import React, { useState, useId } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Button, FormHelperText } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import SchoolIcon from "@mui/icons-material/School";
import CreateIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addExternalCourses,
  editExternalCourses,
  removeEducation,
  removeExternalCourses,
} from "../../../Slices/resume";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pl";
import dayjs from "dayjs";

function ExternalCourses() {
  const externalCoursesList = useSelector(
    (state) => state.resume.externalCourses
  );
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const [coursesData, setCoursesData] = useState({
    courseName: "",
    category: "",
    issueDate: "",
    description: "",
    isEdit: false,
  });

  const EducationSchema = yup.object().shape({
    courseName: yup
      .string()
      .required("Nazwa kursu / szkolenia jest wymagana")
      .min(3, "Pole nazwa kursu / szkolenia jest za krótkie"),
    category: yup
      .string()
      .required("Kategoria jest wymagana")
      .min(3, "Pole kategoria jest za krótkie"),
    issueDate: yup
      .string()
      .required("Data otrzymania zaświadczenia jest wymagana")
      .default(coursesData.issueDate),
    description: yup
      .string()
      .required("Opis jest wymagany")
      .min(3, "Pole opis jest za krótkie"),
  });

  const form = useForm({
    mode: "onChange",
    resolver: yupResolver(EducationSchema),
  });

  // useEffect(() => {
  //   console.log(coursesData);
  // }, [show]);

  const handleClose = () => {
    setValidated(false);
    setShow(false);
    setCoursesData({
      id: "",
      courseName: "",
      category: "",
      issueDate: "",
      description: "",
      isEdit: false,
    });
  };
  const handleShow = () => setShow(true);

  const coursesCategories = [
    "kursy i szkolenia",
    "języki obce",
    "kompetencje społeczne",
    "kompetencje IT",
    "działalność gospodarcza",
    "zainteresowania",
  ];

  const [validated, setValidated] = useState(false);

  const onSubmit = (data) => {
    if (coursesData.isEdit) {
      dispatch(editExternalCourses({ ...coursesData, ...data }));
    } else {
      dispatch(addExternalCourses(data));
    }
    setShow(false);
    setCoursesData({
      id: "",
      institute: "",
      grade: "",
      fieldOfStudy: "",
      startYear: "",
      endYear: "",
      isEdit: false,
    });
  };

  const onError = (error) => console.log(error);

  const handleEdit = (id) => {
    const form = externalCoursesList[id];
    setCoursesData({ ...form, isEdit: true, id: id });
    setShow(true);
  };

  const handleDelete = (id) => {
    dispatch(removeExternalCourses(id));
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Item>
        <Grid container>
          <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
            <Typography variant="h6">Kursy i szkolenia</Typography>
          </Grid>
          <Grid justifyContent="flex-end">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleShow}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Item>

      <Grid container>
        <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
          {externalCoursesList?.map((course, id) => {
            return (
              <Paper
                elevation={0}
                key={id}
                sx={{
                  p: 2,
                  bgcolor: "background.default",
                  borderBottom: "1px solid #555",
                  borderRadius: 0,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={1} sx={{ alignSelf: "center" }}>
                    <SchoolIcon fontSize="large" sx={{ color: "#d2ab67" }} />
                  </Grid>
                  <Grid item xs={8} sm={9} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6">{course.courseName}</Typography>
                    <Typography variant="caption" sx={{ display: "block" }}>
                      {course.category} • {course.description}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ mb: 0 }}
                    >
                      {dayjs(course.issueDate).format("DD.MM.YYYY")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    sx={{
                      display: "flex",
                      flexFlow: "column-reverse",
                      alignItems: "end",
                      alignSelf: "center",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        handleDelete(id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="create"
                      size="small"
                      onClick={() => {
                        handleEdit(id);
                      }}
                    >
                      <CreateIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Grid>
      </Grid>

      <Modal open={show} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Kursy i szkolenia
          </Typography>
          <FormProvider {...form}>
            <Grid container>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { my: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={form.handleSubmit(onSubmit, onError)}
              >
                <Controller
                  name="courseName"
                  defaultValue={coursesData.courseName}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Nazwa kursu / szkolenia "
                      placeholder="np: kurs sushi"
                      value={value}
                      onChange={onChange}
                      helperText={error && error.message}
                      error={error !== undefined}
                      sx={{ width: "100%" }}
                    />
                  )}
                />

                <Controller
                  name="category"
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth sx={{ my: 1 }} error={error && true}>
                      <InputLabel id="courseCategory">
                        Kategoria kursu
                      </InputLabel>
                      <Select
                        defaultValue={coursesData.category}
                        value={value}
                        labelId="courseCategory"
                        label="Kategoria kursu"
                        onChange={onChange}
                        sx={{ width: "100%" }}
                      >
                        <MenuItem value="" key="0">
                          Kategoria kursu
                        </MenuItem>
                        {coursesCategories.map((category, index) => {
                          return (
                            <MenuItem key={index + 1} value={category}>
                              {category}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="issueDate"
                      defaultValue={coursesData.issueDate}
                      rules={{ required: true }}
                      shouldUnregister="true"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pl"
                        >
                          <DatePicker
                            label="Data otrzymania zaświadczenia"
                            value={dayjs(value)}
                            onChange={onChange}
                            slotProps={{
                              textField: {
                                helperText: error && error.message,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </Grid>

                <Controller
                  name="description"
                  defaultValue={coursesData.description}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Opis"
                      placeholder="np. Pracuje jako przedstawiciel handlowy"
                      value={value}
                      onChange={onChange}
                      helperText={error && error.message}
                      error={error !== undefined}
                      sx={{ width: "100%", mt: "15px" }}
                    />
                  )}
                />

                <Button variant="outlined" type="submit" sx={{ mt: "10px" }}>
                  Zapisz zmiany
                </Button>
              </Box>
            </Grid>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default ExternalCourses;
