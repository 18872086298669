import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { SectionPaper } from "../../Components/SectionPaper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ResponsivePie } from "@nivo/pie";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ProfileSectionsStatsBar } from "../../Components/competenceProfilesComponents/ProfileSectionsBar";
import { getCompetenceProfile } from "../../Slices/competenceProfiles";
import moment from "moment";
import SubgroupTable from "../../Components/competenceProfilesComponents/SubgroupTable";
import ProfileUserScore from "../../Components/competenceProfilesComponents/ProfileUserScore";
require("moment/locale/pl");

const matrix = [
  // {
  //   type: "kwalifikacje_bazowe",
  //   translation: "Kwalifikacje Bazowe",
  // },
  // {
  //   type: "kwalifikacje_uzupelniajace",
  //   translation: "Kwalifikacje Uzupełniające",
  // },
  // {
  //   type: "jezyki_obce",
  //   translation: "Języki Obce",
  // },
  // {
  //   type: "kompetencje_it",
  //   translation: "Kompetencje IT",
  // },
  // {
  //   type: "kompetencje_spoleczne",
  //   translation: "Kompetencje Społeczne",
  // },
  // {
  //   type: "dzialalnosc_gospodarcza",
  //   translation: "Dzialalność Gospodarcza",
  // },
  {
    type: "sekcja_doswiadczenie",
    translation: "Sekcja doświadczenie",
    section: "EXPERIENCE",
  },
  {
    type: "sekcja_dzialalnosc_gospodarcza",
    translation: "Sekcja dzialalność gospodarcza",
    section: "ECONOMIC_ACTIVITY",
  },
  {
    type: "sekcja_jezyki",
    translation: "Sekcja języki",
    section: "LANGUAGES",
  },
  {
    type: "sekcja_kompetencje_it",
    translation: "Sekcja kompetencje IT",
    section: "IT_COMPETENCES",
  },
  {
    type: "sekcja_kompetencje_spoleczne",
    translation: "Sekcja kompetencje społeczne",
    section: "SOCIAL_COMPETENCES",
  },
  {
    type: "sekcja_kursy",
    translation: "Sekcja kursy",
    section: "COURSES_AND_EXTERNAL_TRAININGS",
  },
  {
    type: "sekcja_kwalifikacje",
    translation: "Sekcja kwalifikacje",
    section: "QUALIFICATIONS",
  },
  {
    type: "sekcja_wyksztalcenie",
    translation: "Sekcja wykształcenie",
    section: "EDUCATION",
  },
  {
    type: "sekcja_zainteresowania",
    translation: "Sekcja zainteresowania",
    section: "INTERESTS",
  },
];

const CompetenceProfileSection = () => {
  let { profileId, sectionName } = useParams();
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.auth.user.jwt);
  const profile = useSelector((state) => state.competenceProfile);

  useEffect(() => {
    dispatch(getCompetenceProfile({ id: profileId, token: jwt }));
  }, [profileId, jwt]);

  return (
    <main>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
        >
          {profile?.profileName}{" "}
          {` - ${matrix.find((item) => item.type === sectionName).translation}`}
        </Typography>
      </Box>
      <Container container spacing={0}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <SubgroupTable
              sectionName={
                matrix.find((item) => item.type === sectionName).translation
              }
              section={matrix.find((item) => item.type === sectionName).section}
              profileId={profileId}
            />
            <ProfileUserScore />
          </Grid>
          <Grid item xs={6}>
            <Grid container gap={4}>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {profile[sectionName]?.map((element) => (
                  <Link
                    to={
                      element.owned
                        ? `/odznaki/${element.id}`
                        : `/odznaki-do-odblokowania/${element.id}`
                    }
                    style={{
                      textDecoration: "none",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    <ListItem sx={{ opacity: element.owned ? 1 : 0.3 }}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            borderRadius: 0,
                            height: "46px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <img
                            // src={element.image.data.attributes.url}
                            src={element.image?.url}
                            alt={element.badgeName}
                            width="100%"
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={element.badgeName}
                        secondary={
                          element.owned
                            ? moment(element.issuanceDate).format("LL")
                            : "Badge jeszcze nie odblokowany"
                        }
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default CompetenceProfileSection;
