// import React, { useState, useEffect, useRef } from "react";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import { useDispatch, useSelector } from "react-redux";
// import { getCertificates } from "../../Slices/certificates";
// import { useParams } from "react-router-dom";
// import { createSelector } from "reselect";
// import Copyright from "../../Components/Copyright";
// import DownloadIcon from "@mui/icons-material/Download";
// import PrintIcon from "@mui/icons-material/Print";
// import { useReactToPrint } from "react-to-print";
// import useDownload from "../../Hooks/useDownload";

// export default function SingleCertificate() {
//   let { certificateId } = useParams();
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.auth);

//   const { download } = useDownload();

//   const selectCertificates = createSelector(
//     (state) => state.certificates,
//     (certificates) =>
//       certificates.filter((cert) => cert.attributes.cerId === certificateId)[0]
//         .attributes
//   );

//   const componentRef = useRef();
//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current,
//   });

//   const cert = useSelector(selectCertificates);

//   useEffect(() => {
//     dispatch(getCertificates(user.user.email));
//   }, [user]);

//   return (
//     <main>
//       {cert && (
//         <Box
//           sx={{
//             bgcolor: "background.paper",
//             pt: 8,
//             pb: 6,
//           }}
//         >
//           <Container maxWidth="sm">
//             <Typography
//               component="h1"
//               variant="h2"
//               align="center"
//               color="text.primary"
//               gutterBottom
//             >
//               Certyfikat
//             </Typography>
//             <Typography
//               variant="h4"
//               align="center"
//               color="text.secondary"
//               paragraph
//             >
//               {cert && cert.name}
//             </Typography>
//             <Typography
//               variant="h6"
//               align="center"
//               color="text.secondary"
//               paragraph
//             >
//               Numer certyfikatu: {cert && cert.cerId}
//             </Typography>
//             <Typography
//               variant="h6"
//               align="center"
//               color="text.secondary"
//               paragraph
//             >
//               Data wydania: {cert && cert.date}
//             </Typography>
//             <div style={{ marginTop: "20px", marginBottom: "20px" }}>
//               <img
//                 src={cert && cert.url}
//                 alt={cert && cert.name}
//                 loading="lazy"
//                 style={{ maxWidth: "100%" }}
//                 ref={componentRef}
//               />
//             </div>
//             <Stack
//               sx={{ pt: 4 }}
//               direction="row"
//               spacing={2}
//               justifyContent="center"
//             >
//               <Button variant="outlined" endIcon={<DownloadIcon />}>
//                 <a
//                   style={{ textDecoration: "none", color: "#d2ab67" }}
//                   href={cert.url}
//                   onClick={() =>
//                     download(
//                       "https://vccsystem.eu/wp-content/uploads/2021/01/certyfikat-bez-logotypu-scaled.jpg"
//                     )
//                   }
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   Pobierz
//                 </a>
//               </Button>
//               <Button
//                 variant="outlined"
//                 endIcon={<PrintIcon />}
//                 onClick={handlePrint}
//               >
//                 Drukuj
//               </Button>
//             </Stack>
//           </Container>
//         </Box>
//       )}
//       {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
//     </main>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { getCertificates } from "../../Slices/certificates";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";
import Copyright from "../../Components/Copyright";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import useDownload from "../../Hooks/useDownload";
import { styled } from "@mui/material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import cert_img from "../../assets/wzor_cert.png";
import { fontCalibri } from "../../assets/fonts/Calibri-normal.js";
import QRCode from "react-qr-code";
import dayjs from "dayjs";

const useStyles = () => ({
  cert: {
    position: "relative",
    imageRendering: "auto",
  },
  userName: {
    position: "absolute",
    top: "42%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  CertNumber: {
    fontSize: "6px",
    position: "absolute",
    top: "62%",
    left: "17%",
    transform: "translate(-50%, -50%)",
  },
  IssuanceDate: {
    fontSize: "6px",
    position: "absolute",
    top: "86%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  CertName: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const Cert = styled("div")(({ theme }) => ({
  ...useStyles(theme).cert,
}));

const UserName = styled(Typography)(({ theme }) => ({
  ...useStyles(theme).userName,
}));

const CertNumber = styled(Typography)(({ theme }) => ({
  ...useStyles(theme).CertNumber,
}));
const IssuanceDate = styled(Typography)(({ theme }) => ({
  ...useStyles(theme).IssuanceDate,
}));
const CertName = styled(Typography)(({ theme }) => ({
  ...useStyles(theme).CertName,
}));

export default function SingleCertificate() {
  let { certificateId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();
  const qrCodeRef = useRef(null);
  // const [qrcc, setQrcc] = useState();

  const { download } = useDownload();

  // const selectCertificates = createSelector(
  //   (state) => state.certificates,
  //   (certificates) =>
  //     certificates.filter((cert) => cert.attributes.cerId === certificateId)[0]
  //       .attributes
  // );

  const selectCertificates = createSelector(
    (state) => state.certificates,
    (certificates) =>
      certificates.filter(
        (certificate) => certificate.id === Number(certificateId)
      )[0]
  );

  const cert = useSelector(selectCertificates);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(getCertificates(user.user.email));
  }, [user]);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    const qrImage = document.getElementById("qrCode");

    const scale = 2;
    const pdfWidth = 297;

    html2canvas(input, { useCORS: true, scale: scale }).then((canvas) => {
      const doc = new jsPDF("l", "mm");

      const externalImage = new Image();
      externalImage.src =
        "http://localhost:3000/static/media/wzor_cert.2593f7a02c32f220c55d.png";
      doc.addImage(
        externalImage,
        "JPEG",
        0,
        0,
        doc.internal.pageSize.getWidth(),
        doc.internal.pageSize.getHeight(),
        null,
        "NONE"
      );

      const centerX = doc.internal.pageSize.getWidth() / 2;

      doc.addFileToVFS("Calibri.ttf", fontCalibri);
      doc.addFont("Calibri.ttf", "Calibri", "normal");
      doc.setFont("Calibri");

      doc.setFontSize(20);
      doc.setTextColor(0, 0, 0);

      doc.text(dayjs(cert.issuanceDate).format("DD.MM.YYYY"), centerX, 183, {
        align: "center",
      });

      doc.text(cert.badgeName, centerX, 113, {
        align: "center",
      });

      doc.text(
        dayjs(cert.exam_expiry_date).format("DD.MM.YYYY"),
        centerX,
        196,
        {
          align: "center",
        }
      );

      doc.text(`${cert.user.firstname} ${cert.user.lastname}`, centerX, 90, {
        align: "center",
      });

      doc.setFontSize(8);
      doc.text("SC-03-526-01-6827463", 54, 131, { align: "center" });

      doc.addSvgAsImage(qrCodeRef.current.outerHTML, 31, 92, 33, 33);
      setTimeout(() => doc.save("certificate.pdf"), 500);
    });
  };

  return (
    <main>
      {cert && (
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Certyfikat
            </Typography>
            <Typography
              variant="h4"
              align="center"
              color="text.secondary"
              paragraph
            >
              {cert && cert.badgeName}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              paragraph
            >
              Numer certyfikatu: {cert && cert.id}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              paragraph
            >
              Data wydania:{" "}
              {cert && dayjs(cert.issuanceDate).format("DD.MM.YYYY")}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              paragraph
            >
              Data przyznania TalentCoina:{" "}
              {cert && dayjs(cert.exam_expiry_date).format("DD.MM.YYYY")}
            </Typography>
            <div
              style={{
                // backgroundImage: `url(${cert_img})`,
                backgroundImage: `url(${cert.certificate_url})`,
                backgroundSize: "contain",
              }}
            >
              <Cert
                id="divToPrint"
                className={classes.cert}
                style={{
                  height: "392.44px",
                  width: "552px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <QRCode
                  // value="http://localhost:3000/public-badge/dB7dC6C7-DF1C-dBa7-5EDB-EE6cee865cA8"
                  value={`"http://localhost:3000/public-badge/${cert.slug}`}
                  id="qrCode"
                  ref={qrCodeRef}
                  style={{
                    height: "auto",
                    maxWidth: "40px",
                    width: "40px",
                    position: "absolute",
                    top: "188px",
                    left: "66px",
                  }}
                />
                {/* <img
                src={cert && cert.url}
                alt={cert && cert.name}
                loading="lazy"
                style={{ maxWidth: "100%" }}
                ref={componentRef}
              /> */}
                <UserName
                  sx={{ position: "absolute" }}
                >{`${cert.user.firstname} ${cert.user.lastname}`}</UserName>
                <CertName
                  sx={{ position: "absolute" }}
                >{`${cert.badgeName}`}</CertName>
                <CertNumber sx={{ position: "absolute" }}>
                  {`SC-03-526-01-${cert.id}`}
                </CertNumber>
                <IssuanceDate sx={{ position: "absolute" }}>
                  {`${dayjs(cert.issuanceDate).format("DD.MM.YYYY")}`}
                </IssuanceDate>
              </Cert>
            </div>

            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button
                variant="outlined"
                endIcon={<DownloadIcon />}
                onClick={printDocument}
              >
                Pobierz
                {/* <a
                  style={{ textDecoration: "none", color: "#d2ab67" }}
                  href={cert.url}
                  onClick={() =>
                    download(
                      "https://vccsystem.eu/wp-content/uploads/2021/01/certyfikat-bez-logotypu-scaled.jpg"
                    )
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Pobierz
                </a> */}
              </Button>
              {/* <Button
                variant="outlined"
                endIcon={<PrintIcon />}
                onClick={handlePrint}
              >
                Drukuj
              </Button> */}
            </Stack>
          </Container>
        </Box>
      )}
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </main>
  );
}
