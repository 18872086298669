import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import {
  CircularProgress,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// const handleClick = (index) => {
//   console.log("clicked", index);
// };

export default function UploadButton({
  onChange,
  error,
  value,
  progress,
  onUpload,
}) {
  return (
    <>
      {!value && (
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          color={error && "error"}
        >
          Dodaj załącznik
          <VisuallyHiddenInput multiple type="file" onChange={onChange} />
        </Button>
      )}
      {value && (
        <Button
          variant="outlined"
          onClick={(e) => onUpload(value)}
          error={error && error.message}
          value={value}
        >
          Prześlij pliki
        </Button>
      )}
      {error && (
        <Typography
          sx={{
            "font-weight": 400,
            "font-size": "0.75rem",
            "line-height": "1.66",
            "letter-spacing": "0.03333em",
            marginTop: "5px",
          }}
          variant="body2"
          color="error"
        >
          {error}
        </Typography>
      )}
      {value && (
        <LinearProgress
          variant="determinate"
          value={progress ? progress : 0}
          sx={{ mt: 5 }}
        />
      )}
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {value &&
          [...value].map((val, i) => (
            <ListItem
              key={i}
              disableGutters
              // secondaryAction={
              //   <IconButton aria-label="comment" onClick={() => handleClick(i)}>
              //     <DeleteOutlineIcon />
              //   </IconButton>
              // }
              sx={{ borderBottom: "1px solid #e0e0e0" }}
            >
              <ListItemText primary={val.name} />
            </ListItem>
          ))}
      </List>
    </>
  );
}
