import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Button, FormHelperText } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import SchoolIcon from "@mui/icons-material/School";
import CreateIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TranslateIcon from "@mui/icons-material/Translate";
import {
  addForeignLanguages,
  editForeignLanguages,
  removeForeignLanguages,
} from "../../../Slices/resume";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pl";
import dayjs from "dayjs";

function ForeignLanguages() {
  const foreignLanguagesList = useSelector(
    (state) => state.resume.foreignLanguages
  );
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const [languageData, setLanguageData] = useState({
    level: "",
    languageName: "",
    issueDate: "",
    description: "",
    isEdit: false,
  });

  const ForeignLanguagesSchema = yup.object().shape({
    level: yup.string().required("Poziom zaawansowania jest wymagany"),
    languageName: yup
      .string()
      .required("Nazwa języka jest wymagana")
      .min(3, "Pole nazwa języka jest za krótkie"),
    issueDate: yup
      .string()
      .required("Data wystawienia jest wymagana")
      .min(3, "Pole data wystawienia jest za krótkie"),
    description: yup
      .string()
      .required("Opis jest wymagany")
      .default(languageData.startYear),
  });

  const form = useForm({
    mode: "onChange",
    resolver: yupResolver(ForeignLanguagesSchema),
  });

  const handleClose = () => {
    setShow(false);
    setLanguageData({
      id: "",
      level: "",
      languageName: "",
      issueDate: "",
      description: "",
      isEdit: false,
    });
  };
  const handleShow = () => setShow(true);

  const languageLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];

  const onSubmit = (data) => {
    if (languageData.isEdit) {
      dispatch(editForeignLanguages({ ...languageData, ...data }));
    } else {
      dispatch(addForeignLanguages(data));
    }
    setShow(false);
    setLanguageData({
      id: "",
      level: "",
      languageName: "",
      issueDate: "",
      description: "",
      isEdit: false,
    });
  };

  const onError = (error) => console.log(error);

  const handleEdit = (id) => {
    const form = foreignLanguagesList[id];
    setLanguageData({ ...form, isEdit: true, id: id });
    setShow(true);
  };

  const handleDelete = (id) => {
    dispatch(removeForeignLanguages(id));
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Item>
        <Grid container>
          <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
            <Typography variant="h6">Języki obce</Typography>
          </Grid>
          <Grid justifyContent="flex-end">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleShow}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Item>

      <Grid container>
        <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
          {foreignLanguagesList?.map((language, id) => {
            return (
              <Paper
                elevation={0}
                key={id}
                sx={{
                  p: 2,
                  bgcolor: "background.default",
                  borderBottom: "1px solid #555",
                  borderRadius: 0,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={1} sx={{ alignSelf: "center" }}>
                    <TranslateIcon fontSize="large" sx={{ color: "#d2ab67" }} />
                  </Grid>
                  <Grid item xs={8} sm={9} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6">
                      {language.languageName}
                    </Typography>
                    <Typography variant="caption" sx={{ display: "block" }}>
                      {dayjs(language.issueDate).format("DD.MM.YYYY")} •{" "}
                      {language.description}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ mb: 0 }}
                    >
                      {language.description}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    sx={{
                      display: "flex",
                      flexFlow: "column-reverse",
                      alignItems: "end",
                      alignSelf: "center",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        handleDelete(id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="create"
                      size="small"
                      onClick={() => {
                        handleEdit(id);
                      }}
                    >
                      <CreateIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Grid>
      </Grid>

      <Modal open={show} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Języki obce
          </Typography>
          <FormProvider {...form}>
            <Grid container>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { my: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={form.handleSubmit(onSubmit, onError)}
              >
                <Controller
                  name="level"
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth sx={{ my: 1 }} error={error && true}>
                      <InputLabel id="level">Poziom zaawansowania</InputLabel>
                      <Select
                        defaultValue={languageData.level}
                        // value={value}
                        labelId="level"
                        label="Poziom zaawansowania"
                        onChange={onChange}
                        sx={{ width: "100%" }}
                      >
                        <MenuItem value="" key="0">
                          Poziom zaawansowania
                        </MenuItem>
                        {languageLevels.map((level, index) => {
                          return (
                            <MenuItem key={index + 1} value={level}>
                              {level}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />

                <Controller
                  name="languageName"
                  defaultValue={languageData.languageName}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Nazwa języka"
                      placeholder="np: Język angielski"
                      value={value}
                      onChange={onChange}
                      helperText={error && error.message}
                      error={error !== undefined}
                      sx={{ width: "100%" }}
                    />
                  )}
                />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="issueDate"
                      defaultValue={languageData.issueDate}
                      rules={{ required: true }}
                      shouldUnregister="true"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pl"
                        >
                          <DatePicker
                            label="Data otrzymania zaświadczenia"
                            value={dayjs(value)}
                            onChange={onChange}
                            slotProps={{
                              textField: {
                                helperText: error && error.message,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </Grid>

                <Controller
                  name="description"
                  defaultValue={languageData.description}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Opis"
                      placeholder=""
                      value={value}
                      onChange={onChange}
                      helperText={error && error.message}
                      error={error !== undefined}
                      sx={{ width: "100%", mt: "15px" }}
                    />
                  )}
                />

                <Button variant="outlined" type="submit" sx={{ mt: "10px" }}>
                  Zapisz zmiany
                </Button>
              </Box>
            </Grid>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default ForeignLanguages;
