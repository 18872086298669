import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment/moment";

const matrix = [
  {
    type: "kwalifikacje_bazowe",
    translation: "Kwalifikacje Bazowe",
  },
  {
    type: "kwalifikacje_uzupelniajace",
    translation: "Kwalifikacje Uzupełniające",
  },
  {
    type: "jezyki_obce",
    translation: "Języki Obce",
  },
  {
    type: "kompetencje_it",
    translation: "Kompetencje IT",
  },
  {
    type: "kompetencje_spoleczne",
    translation: "Kompetencje Społeczne",
  },
  {
    type: "dzialalnosc_gospodarcza",
    translation: "Dzialalność Gospodarcza",
  },
  {
    type: "sekcja_doswiadczenie",
    translation: "Sekcja doświadczenie",
  },
  {
    type: "sekcja_dzialalnosc_gospodarcza",
    translation: "Sekcja dzialalność gospodarcza",
  },
  {
    type: "sekcja_jezyki",
    translation: "Sekcja języki",
  },
  {
    type: "sekcja_kompetencje_it",
    translation: "Sekcja kompetencje IT",
  },
  {
    type: "sekcja_kompetencje_spoleczne",
    translation: "Sekcja kompetencje społeczne",
  },
  {
    type: "sekcja_kursy",
    translation: "Sekcja kursy",
  },
  {
    type: "sekcja_kwalifikacje",
    translation: "Sekcja kwalifikacje",
  },
  {
    type: "sekcja_wyksztalcenie",
    translation: "Sekcja wykształcenie",
  },
  {
    type: "sekcja_zainteresowania",
    translation: "Sekcja zainteresowania",
  },
];

const ProfileSectionElement = ({
  profile,
  name,
  section,
  expanded,
  setExpanded,
}) => {
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let calculatePercentage = (array) => {
    if (array) {
      const userBadges = array.filter((el) => el.owned).length;
      const allBadges = array.length;
      if (userBadges !== 0) {
        return (userBadges / allBadges) * 100;
      }
      return 0;
    }
  };

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setProgress(calculatePercentage(section));
    }, 150);
  }, [section]);

  return (
    <ListItem
      sx={{
        width: "100%",
        borderRadius: "7px",
        marginBottom: "25px",
        boxShadow:
          "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 10px 1px rgba(0, 0, 0, 0.14), 0px -3px 3px -3px rgba(0, 0, 0, 0.20)",
      }}
    >
      <ListItemSecondaryAction sx={{ zIndex: 2 }}>
        <Button
          onClick={() => navigate(`sekcja/${name}`)}
          variant="outlined"
          color={calculatePercentage(section) >= 80 ? "secondary" : "primary"}
        >
          Więcej
        </Button>
      </ListItemSecondaryAction>
      <ListItemText
        primary={`${
          matrix.find((item) => item.type === name).translation
        } | ${Math.floor(calculatePercentage(section))}%`}
        style={{
          zIndex: 1,
        }}
      />
      <div
        id="progressBar"
        style={{
          width: `${progress}%`,
          transition: `width 1s ease`,
          height: "100%",
          background: "#D2AB67",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
          borderRadius: "7px",
        }}
      ></div>
      {/* <div
        style={{
          width: `${calculatePercentage(section)}%`,
          height: "100%",
          background: "#D2AB67",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
          borderRadius: "7px",
        }}
      ></div> */}
    </ListItem>
  );
};

export default ProfileSectionElement;
