import React, { useState } from "react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Box,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";

const LinkZoneWithCopyButton = ({ slug }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_MEDIA_URL}/public-badge/${slug}`
    );
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          variant="outlined"
          value={
            slug !== null
              ? `${process.env.REACT_APP_MEDIA_URL}/public-badge/${slug}`
              : "Link do udostępniania jeszcze niedostępny"
          }
          disabled
          sx={{
            backgroundColor: "#5555551c",
            borderRadius: 1,
            width: "400px",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  aria-label="skopiuj link"
                  onClick={handleCopy}
                  sx={{
                    border: `2px solid ${
                      slug !== null ? "#d2ab67" : "#afafaf"
                    }`,
                    borderRadius: 1,
                  }}
                  disabled={slug === null}
                >
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Skopiowano link"
      />
    </>
  );
};

export default LinkZoneWithCopyButton;
