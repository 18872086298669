import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Button,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styled from "styled-components";
import DropZone from "../../Components/DropZone";
import badgeGold from "../../assets/badge_gold.png";
import badgeSilver from "../../assets/badge_silver.png";
import badgeBrown from "../../assets/badge_brown.png";
import badgeService from "../../Actions/openBadgeServices";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";

const matrix = [
  {
    type: "JWT_SIGNATURE_VERIFIED",
    translation: "Podpis cyfrowy został zweryfikowany prawidłowo",
  },
  {
    type: "JWT_ISS_MATCHES_VC_ISSUER_ID",
    translation: "Podpis cyfrowy jest zgodny z identyfikatorem wydawcy",
  },
  {
    type: "JWT_SUB_MATCHES_VC_SUBJECT_ID",
    translation: "Podpis cyfrowy jest zgodny z identyfikatorem poświadczenia",
  },
  {
    type: "JWT_NBF_EQUALS_VC_ISSUANCE_DATE",
    translation: "Zweryfikowano prawidłowo datę wydania poświadczenia",
  },
  {
    type: "JWT_JTI_MATCHES_VC_ID",
    translation: "Identyfikator poświadczenia jest unikalny",
  },
  {
    type: "JWT_EXP_EQUALS_VC_EXPIRATION_DATE",
    translation: "Zweryfikowano prawidłowo datę wygaśnięcia poświadczenia",
  },
  {
    type: "VC_VALID",
    translation: "Data wydania poświadczenia zweryfikowana prawidłowo",
  },
  {
    type: "VC_NOT_EXPIRED",
    translation: "Poświadczenie jest ważne",
  },
];

function Validator() {
  const [file, setFile] = useState();
  const [currentIndex, setCurrentIndex] = useState(1);
  const [data, setData] = useState([]);
  const [assignedBadgeData, setAssignedBadgeData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [finalJudgement, setFinalJudgement] = useState(false);
  const [imageURL, setImageURL] = useState("");

  if (file && file[0]) {
    const reader = new FileReader();

    reader.onload = () => {
      setImageURL(reader.result);
    };

    reader.readAsDataURL(file[0]);
  }

  const handleClick = () => {
    setValidated(true);
    badgeService.validateBadgeImage(file).then((res) => {
      const mappedTranslations = res.report.results.map(({ type }) => {
        const foundTranslation = matrix.find((item) => item.type === type);

        return foundTranslation
          ? foundTranslation.translation
          : "Translation not found";
      });

      setData(mappedTranslations);
      setAssignedBadgeData(res.assignedBadgePayload);
      // setData(res.results);
      setFinalJudgement(res.report.finalJudgement);
    });

    // badgeService.validateBadgeImage(file);
    // badgeService.validateBadgeImageFaked().then((res) => setData(res.results));
  };

  useEffect(() => {
    const delay = Math.floor(Math.random() * (600 - 100 + 1)) + 100;
    if (currentIndex < data.length - 1) {
      const timer = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentIndex, data]);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} textAlign="center">
        <Typography component="h1" variant="h4" sx={{ mt: 2 }}>
          Sprawdź autentyczność badga
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Box
          sx={{
            m: 2,
            borderRadius: 2,
            // display: "grid",
            // gridTemplateColumns: { md: "1fr" },
            // gap: 2,
            textAlign: "center",
          }}
        >
          <Paper elevation={3}>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "center", minHeight: "600px" }}
            >
              {!validated && (
                <>
                  <Grid item xs={12}>
                    <Typography component="h4" variant="h5">
                      Nasz system zakłada trzy rodzaje odznak:
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <img
                          src={badgeGold}
                          alt="badge"
                          style={{ width: "60%", marginTop: "20px" }}
                        />
                        <Typography
                          sx={{
                            textTransform: "uppercase",
                            color: "#F39200",
                            fontSize: "12px",
                          }}
                        >
                          Odznaka złota - przyznawana za zdanie egzaminu
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <img
                          src={badgeSilver}
                          alt="badge"
                          style={{ width: "60%", marginTop: "20px" }}
                        />
                        <Typography
                          sx={{
                            textTransform: "uppercase",
                            color: "#F39200",
                            fontSize: "12px",
                          }}
                        >
                          Odznaka srebrna - przyznawana za grupy efektów uczenia
                          się (składowa odznaki złotej)
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <img
                          src={badgeBrown}
                          alt="badge"
                          style={{ width: "60%", marginTop: "20px" }}
                        />
                        <Typography
                          sx={{
                            textTransform: "uppercase",
                            color: "#F39200",
                            fontSize: "12px",
                          }}
                        >
                          Odznaka brązowa - przyznawana za osiągnięcia własne
                          spoza systemu VCC
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Przeciągnij odznakę lub kliknij w pole aby wybrać odznakę
                      z dysku.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <DropZone setFile={setFile} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleClick()}
                        sx={{
                          width: "100%",
                          margin: "30px",
                          padding: "10px",
                        }}
                      >
                        SPRAWDŹ
                      </Button>
                    </Box>
                  </Grid>
                </>
              )}

              {validated && finalJudgement && (
                <Grid item xs={12} sx={{ minHeight: "720px" }}>
                  <Typography variant="h6" component="h2">
                    {assignedBadgeData.vc?.name}
                  </Typography>
                  <Grid container>
                    <Grid item md={6} sx={{ alignSelf: "center" }}>
                      {imageURL && (
                        <img
                          src={imageURL}
                          alt="Uploaded"
                          style={{ maxWidth: "250px" }}
                        />
                      )}
                      {finalJudgement && currentIndex >= data.length - 1 && (
                        <>
                          <Typography variant="subtitle2">
                            Wystawca: {assignedBadgeData.vc?.issuer.name}
                          </Typography>
                          <Typography variant="subtitle2">
                            Data Wystawienia:{" "}
                            {moment(assignedBadgeData.vc?.awardedDate).format(
                              "LL"
                            )}
                          </Typography>
                        </>
                      )}
                    </Grid>

                    <Grid item md={6}>
                      <List>
                        {data.map((item, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              display: index <= currentIndex ? "flex" : "none",
                              paddingTop: "3px",
                              paddingBottom: "3px",
                            }}
                          >
                            <ListItemIcon>
                              <CheckCircleOutlineIcon sx={{ color: "green" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={item}
                              // sx={{ fontSize: 2, color: "green" }}
                              // primary={item.type}
                              // secondary={item.description}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>

                  {finalJudgement && currentIndex >= data.length - 1 && (
                    <Typography
                      component="h4"
                      variant="h5"
                      sx={{ color: "green", m: 9 }}
                    >
                      Badge został zwalidowany prawidłowo
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      </Grid>
      {/* <div>
        <ul>
          {data.map((item, index) => (
            <li
              //   key={item.id}
              style={{
                display: index <= currentIndex ? "block" : "none",
              }}
            >
              <h2>{item.type}</h2>
              <p>{item.description}</p>
            </li>
          ))}
        </ul>
      </div> */}
    </Grid>
  );
}

export default Validator;
