import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ResponsivePie } from "@nivo/pie";
import { Box, Grid, Typography } from "@mui/material";
import badgeService from "../../Actions/openBadgeServices";
import { SectionPaper } from "../SectionPaper";

export default function SubgroupTable({ sectionName, section, profileId }) {
  const [subgroups, setSubgroups] = useState();

  useEffect(() => {
    badgeService
      .getProfileSubgroups(profileId, section)
      .then((res) => setSubgroups(res.subGroups));
  }, []);

  return (
    <SectionPaper elevation={3} sx={{ height: "330px" }}>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <Typography
            component="h2"
            variant="h6"
            align="center"
            color="text.primary"
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {`Obszary kompetencyjne: ${sectionName}`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: "100%", height: "250px" }}>
            <ResponsivePie
              data={
                !subgroups
                  ? [
                      {
                        // id: "Techniczne sprzętowe - realizacja",
                        // label: "Techniczne sprzętowe - realizacja",
                        // value: 377,
                        // color: "hsl(306, 70%, 50%)",
                      },
                    ]
                  : subgroups?.map((item) => {
                      return {
                        id: item.name,
                        label: item.name,
                        value: item.effectsCount,
                        color: item.effectsCount,
                      };
                    })
              }
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              margin: "10px 0px 0px -10px",
              height: "250px",
            }}
          >
            <Table
              sx={{ maxWidth: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Podgrupa</TableCell>
                  <TableCell align="right">Ilość</TableCell>
                  <TableCell align="right">%</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subgroups?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "9px" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.effectsCount}</TableCell>
                    <TableCell align="right">
                      {row.subGroupPercentage}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </SectionPaper>
  );
}
