import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { SectionPaper } from "../SectionPaper";
import { useDispatch, useSelector } from "react-redux";
import { getPrkStats } from "../../Slices/competencePaths";
import ProfileSectionScoreRadialChart from "../Charts/ProfileSectionScoreRadialChart";

const ProfileUserScore = () => {
  const dispatch = useDispatch();
  const profileScore = useSelector((state) => state.competencePahts.prkStats);
  const token = useSelector((state) => state.auth.user.jwt);

  useEffect(() => {
    dispatch(getPrkStats(token));
  }, [dispatch, token]);

  return (
    <SectionPaper
      elevation={3}
      sx={{ alignItems: "center", height: "250px", marginY: "40px" }}
    >
      <Grid item xs={12} height={200}>
        <Typography variant="h6">
          Twój wynik na tle innych osób o tym samym profilu
        </Typography>
        <ProfileSectionScoreRadialChart profileScore={profileScore} />
      </Grid>
    </SectionPaper>
  );
};

export default ProfileUserScore;
