import React, { useState, useId } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { bindActionCreators } from "redux";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Button,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import SchoolIcon from "@mui/icons-material/School";
import CreateIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  addEducation,
  editEducation,
  removeEducation,
} from "../../../Slices/resume";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pl";
import dayjs from "dayjs";
import UploadButton from "../../../Components/UploadButton";
import userService from "../../../Actions/services";

function Education() {
  const educationList = useSelector((state) => state.resume.educationList);
  const { jwt, user } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState();

  const [show, setShow] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [educationData, setEducationData] = useState({
    school: "",
    degree: "",
    educationLevel: "",
    startDate: "",
    endDate: "",
    grade: "",
    isEdit: false,
    file: "",
  });

  const EducationSchema = yup.object().shape({
    school: yup
      .string()
      .required("Szkoła jest wymagana")
      .min(3, "Pole szkoła jest za krótkie"),
    degree: yup
      .string()
      .required("Tytuł jest wymagany")
      .min(3, "Pole tytuł jest za krótkie"),
    educationLevel: yup
      .string()
      .required("Poziom wykształcenia jest wymagany")
      .min(3, "Pole poziom wykształcenia jest za krótkie"),
    startDate: yup
      .string()
      .required("Rok rozpoczęcia jest wymagany")
      .default(educationData.startYear),
    endDate: yup
      .string()
      .required("Rok zakończenia jest wymagany")
      .default(educationData.endYear),
    grade: yup
      .number()
      .typeError("Ocena misi być liczbą")
      .positive("Ocena nie może być ujemna")
      .min(1, "Minimalna ocena to 1")
      .max(5, "Maksymalna ocena to 5")
      .required("Ocena jest wymagana"),
    file: yup
      .mixed()
      .required("Plik jest wymagany")
      .test("fileType", "Nieobsługiwany typ pliku", (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (
              [
                "image/jpg",
                "image/jpeg",
                "image/png",
                "application/pdf",
              ].includes(value[i].type)
            ) {
              return true;
            }
          }
        }
      })
      .test("fileSize", "Plik jest zbyt duży", (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > 2000000) {
              return false;
            }
          }
        }
        return true;
      }),
  });

  const form = useForm({
    mode: "onChange",
    resolver: yupResolver(EducationSchema),
  });

  const dayAfter = dayjs(form.watch("startDate")).add(1, "day");

  useEffect(() => {
    userService
      .getResumeAttachedFiles(163, jwt)
      .then((res) => setAttachments(res.data.data.attributes.filesEducation));
  }, [show, jwt]);

  const handleClose = () => {
    setValidated(false);
    setShow(false);
    setEducationData({
      id: "",
      school: "",
      educationLevel: "",
      degree: "",
      startDate: "",
      endDate: "",
      isEdit: false,
      grade: "",
    });
  };
  const handleShow = () => setShow(true);
  // const handleAlertClose = () => setAlert(false);
  // const handleAlert = (id) => {
  //   setDeleteId(id);
  //   setAlert(true);
  // };

  // const handleForm = (e) => {
  //   setEducationData((old) => {
  //     console.log(old, e);
  //     return {
  //       ...old,
  //       // id: randomId,
  //       [e.target.name]:
  //         e.target.type === "checkbox" ? e.target.checked : e.target.value,
  //     };
  //   });
  // };

  const educationLevels = [
    "wykształcenie podstawowe",
    "wykształcenie gimnazjalne",
    "wykształcenie zasadnicze zawodowe",
    "wykształcenie zasadnicze branżowe",
    "wykształcenie średnie branżowe",
    "wykształcenie średnie",
    "wykształcenie wyższe",
  ];

  const [validated, setValidated] = useState(false);

  const onSubmit = (data) => {
    if (educationData.isEdit) {
      dispatch(editEducation({ ...educationData, ...data }));
    } else {
      dispatch(addEducation(data));
    }
    setShow(false);
    setEducationData({
      id: "",
      school: "",
      educationLevel: "",
      degree: "",
      startDate: "",
      endDate: "",
      isEdit: false,
      grade: "",
    });
  };

  const onUpload = async () => {
    try {
      const response = userService.uploadFile(
        jwt,
        user,
        form.watch("file"),
        "filesEducation",
        setProgress
      );

      const responseData = await response;

      // const updatedData = attachments.data.filter((item) => item.id !== id);
      let newArray = [];
      responseData.data.forEach((item) => {
        newArray.push({ id: item.id, attributes: item });
      });

      setAttachments({
        data: [...attachments.data, ...newArray],
      });

      console.log(progress);
      if (responseData) {
        form.reset({ file: null });
        setProgress(0);
        console.log(form.watch("file"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const onUpload = () => {
  //   console.log("on upload file", form.watch("file"));
  //   userService.uploadFile(jwt, form.watch("file").then(function (response) {

  //     dispatch(
  //       update({
  //         id: user.user.id,
  //         token: user.jwt,
  //         payload: { ...data, profileImage: response.data[0].url },
  //       })
  //     );
  //   }));
  // };

  const onError = (error) => console.log(error);

  const handleEdit = (id) => {
    const form = educationList[id];
    setEducationData({ ...form, isEdit: true, id: id });
    setShow(true);
  };

  const handleDelete = (id) => {
    dispatch(removeEducation(id));
    // setAlert(false);
  };

  const deleteAttachment = (id) => {
    console.log(attachments);
    console.log(id);
    userService.removeResumeAttachedFile(id, jwt);
    const updatedData = attachments.data.filter((item) => item.id !== id);
    setAttachments({ data: updatedData });
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Item>
        <Grid container>
          <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
            <Typography variant="h6">Edukacja</Typography>
          </Grid>
          <Grid justifyContent="flex-end">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleShow}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Item>

      <Grid container>
        <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
          {educationList?.map((item, id) => {
            return (
              <Paper
                elevation={0}
                key={id}
                sx={{
                  p: 2,
                  bgcolor: "background.default",
                  borderBottom: "1px solid #555",
                  borderRadius: 0,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={1} sx={{ alignSelf: "center" }}>
                    <SchoolIcon fontSize="large" sx={{ color: "#d2ab67" }} />
                  </Grid>
                  <Grid item xs={8} sm={9} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6">{item.company}</Typography>
                    <Typography variant="caption" sx={{ display: "block" }}>
                      {item.school} • {item.degree}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ mb: 0 }}
                    >
                      {dayjs(item.startDate).format("DD.MM.YYYY")} -{" "}
                      {dayjs(item.endDate).format("DD.MM.YYYY")} • Ocena:{" "}
                      {item.grade}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    sx={{
                      display: "flex",
                      flexFlow: "column-reverse",
                      alignItems: "end",
                      alignSelf: "center",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        handleDelete(id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="create"
                      size="small"
                      onClick={() => {
                        handleEdit(id);
                      }}
                    >
                      <CreateIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Grid>
      </Grid>

      <Modal open={show} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Edukacja
          </Typography>
          <FormProvider {...form}>
            <Grid container>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { my: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
                onSubmit={form.handleSubmit(onSubmit, onError)}
              >
                <Controller
                  name="school"
                  defaultValue={educationData.school}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Szkoła / Uniwersytet"
                      placeholder="np: Wyższa Szkoła w Lubline"
                      value={value}
                      onChange={onChange}
                      helperText={error && error.message}
                      error={error !== undefined}
                      sx={{ width: "100%" }}
                    />
                  )}
                />

                <Controller
                  name="degree"
                  defaultValue={educationData.degree}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Tytuł naukowy / zawodowy"
                      placeholder="np: Magister"
                      value={value}
                      onChange={onChange}
                      helperText={error && error.message}
                      error={error !== undefined}
                      sx={{ width: "100%" }}
                    />
                  )}
                />

                <Controller
                  name="educationLevel"
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth sx={{ my: 1 }} error={error && true}>
                      <InputLabel id="educationLevel">
                        Poziom wykształcenia
                      </InputLabel>
                      <Select
                        defaultValue={educationData.educationLevel}
                        // value={value}
                        labelId="educationLevel"
                        label="Poziom wykształcenia"
                        onChange={onChange}
                        sx={{ width: "100%" }}
                      >
                        <MenuItem value="" key="0">
                          wybierz poziom z listy
                        </MenuItem>
                        {educationLevels.map((level, index) => {
                          return (
                            <MenuItem key={index + 1} value={level}>
                              {level}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption">Data rozpoczęcia</Typography>
                    <Controller
                      name="startDate"
                      defaultValue={educationData.startDate}
                      rules={{ required: true }}
                      shouldUnregister="true"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pl"
                        >
                          <DatePicker
                            label="Data od"
                            value={dayjs(value)}
                            onChange={onChange}
                            slotProps={{
                              textField: {
                                helperText: error && error.message,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="caption">Data zakończenia</Typography>

                    <Controller
                      name="endDate"
                      defaultValue={educationData.endDate}
                      rules={{ required: true }}
                      shouldUnregister="true"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pl"
                        >
                          <DatePicker
                            label="Data do"
                            value={dayjs(value)}
                            onChange={onChange}
                            minDate={dayAfter}
                            slotProps={{
                              textField: {
                                helperText: error && error.message,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Controller
                    name="grade"
                    defaultValue={educationData.grade}
                    rules={{ required: true }}
                    shouldUnregister="true"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        label="Ocena"
                        placeholder="np. 4"
                        value={value}
                        onChange={onChange}
                        helperText={error && error.message}
                        error={error !== undefined}
                        sx={{ width: "100%", mt: "40px" }}
                      />
                    )}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "12px",
                    }}
                  >
                    <Controller
                      name="file"
                      control={form.control}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <UploadButton
                            onChange={(e) => onChange(e.target.files)}
                            error={error && error.message}
                            value={value}
                            progress={progress}
                            onUpload={onUpload}
                            // setEducationData={setEducationData}
                            // educationData={educationData}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "12px",
                    }}
                  >
                    <Typography variant="caption">
                      Pliki w sekcji edukacja:
                    </Typography>
                    <Container maxWidth={false} disableGutters>
                      <List
                        // maxWidth={false}
                        // disableGutters
                        sx={{
                          width: "100%",
                          maxWidth: 345,
                          bgcolor: "background.paper",
                        }}
                      >
                        {attachments.data?.map((val) => (
                          <ListItem
                            key={val.id}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                // onClick={() =>
                                //   userService.removeResumeAttachedFile(
                                //     val.id,
                                //     jwt
                                //   )
                                // }

                                onClick={() => deleteAttachment(val.id)}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            }
                            sx={{ borderBottom: "1px solid #e0e0e0" }}
                          >
                            <ListItemText primary={val.attributes.name} />
                          </ListItem>
                        ))}
                      </List>
                    </Container>
                  </Grid>
                </Grid>
                <Button variant="outlined" type="submit" sx={{ mt: "10px" }}>
                  Zapisz zmiany
                </Button>
              </Box>
            </Grid>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default Education;
