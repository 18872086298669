import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "../../Hooks/useDocumentTitle";
import { Footer } from "../../Components/Footer";
import {
  Box,
  Chip,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";

import { SectionPaper } from "../../Components/SectionPaper";
import StatsBox from "../../Components/competencePathsComponents/StatsBox";
import PrkLevelBox from "../../Components/competencePathsComponents/PrkLevelBox";
import LearningPaths from "../../Components/competencePathsComponents/LearningPaths";
import MostCommonQualifications from "../../Components/competencePathsComponents/MostCommonQualifications";
import { useDispatch, useSelector } from "react-redux";
import { getCompetencePaths } from "../../Slices/competencePaths";

export default function CompetencePaths() {
  useDocumentTitle("Ścieżki kompetencyjne");
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.auth.user.jwt);
  const [currentProfile, setCurrentProfile] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const pagination = useSelector(
    (state) => state.competencePahts.pagination?.pageCount
  );

  const profiles = useSelector((state) => {
    const competencePaths = state.competencePahts.competencePaths;
    const uniqueProfiles = [];
    const uniqueProfilesIds = new Set();

    competencePaths?.filter((el) => {
      const profileId = el.attributes.profile_zawodowe.data[0]?.id;

      if (profileId && !uniqueProfilesIds.has(profileId)) {
        uniqueProfilesIds.add(profileId);
        uniqueProfiles.push(el.attributes?.profile_zawodowe.data[0]);
        return true;
      }
      return false;
    });
    return uniqueProfiles;
  });

  const filteredCompetencePahts = useSelector((state) => {
    const competencePaths = state.competencePahts.competencePaths;

    if (!currentProfile) {
      return competencePaths;
    }
    const filteredPaths = competencePaths?.filter(
      (el) =>
        currentProfile?.id === el.attributes?.profile_zawodowe?.data[0]?.id
    );
    return filteredPaths;
  });

  useEffect(() => {
    dispatch(getCompetencePaths({ token: jwt, page: currentPage }));
  }, [jwt, dispatch, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="text.primary"
          >
            Ścieżki kompetencyjne
          </Typography>
        </Box>
        <Container spacing={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <MostCommonQualifications />
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <StatsBox />
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{ minHeight: "166px" }}>
                  <PrkLevelBox currentProfile={currentProfile} />
                </Grid>

                <Grid item xs={12}>
                  <SectionPaper elevation={3}>
                    <Grid
                      container
                      spacing={3}
                      sx={{
                        alignItems: "stretch",
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "720px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          component="h3"
                          variant="h6"
                          align="center"
                          color="text.primary"
                        >
                          Wybierz profil aby zawęzić wyniki wyszukiwania:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            listStyle: "none",
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {profiles?.map((profile) => (
                            <Chip
                              key={profile?.id}
                              label={profile.attributes?.profileName}
                              variant={
                                profile?.id === currentProfile?.id
                                  ? "filled"
                                  : "outlined"
                              }
                              onClick={() =>
                                setCurrentProfile(
                                  profile.id === currentProfile?.id
                                    ? null
                                    : profile
                                )
                              }
                              sx={{
                                marginBottom: "10px !important",
                                bgcolor:
                                  profile?.id === currentProfile?.id
                                    ? "#55e098"
                                    : null,
                              }}
                            />
                          ))}
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <LearningPaths
                          competencePahts={filteredCompetencePahts}
                        />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Pagination
                            count={pagination}
                            color="primary"
                            page={currentPage}
                            sx={{ justifyContent: "center" }}
                            onChange={handlePageChange}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </SectionPaper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
    </>
  );
}
