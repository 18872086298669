import React, { useCallback } from "react";
import Paper from "@mui/material/Paper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";

const Dropzone = styled("label")({
  border: "2px dashed #fff",
  borderRadius: "15px",
  backgroundColor: "#D9D9D9",
  width: "500px",
  height: "200px",
  margin: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: "#d0d0d0",
  },
  "& input": {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
});

const UploadZone = ({ setFile }) => {
  const handleFileChange = useCallback((event) => {
    const file = event.target.files;
    setFile(file);
  }, []);

  return (
    <Dropzone>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <CloudUploadIcon sx={{ mr: 2 }} />
      <p>Przeciągnij i upuść badga lub kliknij, aby wybrać</p>
    </Dropzone>
  );
};

export default UploadZone;
