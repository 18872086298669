import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { SectionPaper } from "../SectionPaper";
import BestQualificationsRadialBar from "../Charts/BestQualificationsRadialBar";
import { useDispatch, useSelector } from "react-redux";
import { getMostCommonQualifications } from "../../Slices/competencePaths";

const colors = ["#6DA34D", "#56445D", "#548687", "#8FBC94", "#C5E99B"];

const MostCommonQualifications = () => {
  const dispatch = useDispatch();
  const qualifications = useSelector(
    (state) => state.competencePahts.mostCommonQualifications
  );

  useEffect(() => {
    dispatch(getMostCommonQualifications());
  }, [dispatch]);

  return (
    <SectionPaper elevation={3}>
      <Grid container spacing={0} sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="h6"
            align="center"
            color="text.primary"
            pt={2}
          >
            Najczęściej wybierane kwalifikacje:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            {Array.isArray(qualifications) &&
              qualifications?.map((qualification, i) => (
                <ListItem key={i}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: colors[i] }}>
                      {qualification.name
                        .toUpperCase()
                        .split(" ")
                        .map((w) => w[0][0])
                        .slice(0, 3)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={qualification.name} />
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item xs={12} height={"200px"}>
          <BestQualificationsRadialBar
            qualifications={qualifications}
            colors={colors}
          />
        </Grid>
      </Grid>
    </SectionPaper>
  );
};

export default MostCommonQualifications;
