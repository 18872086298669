import React, { useState } from "react";

import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  Typography,
  Slider,
  Select,
  MenuItem,
} from "@mui/material";

import "react-circular-progressbar/dist/styles.css";

function NewSkill({ skillsArr, setSkillsArr, addSkill }) {
  const [input, setInput] = useState();
  const [type, setType] = useState();
  const [percentage, setPercentage] = useState();

  const handleClick = () => {
    setSkillsArr([
      ...skillsArr,
      { name: input, value: percentage, type: type },
    ])
    addSkill({name: input, value: percentage, type: type});
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { sm: "1fr" },
        gap: 1,
        mb: 3,
      }}
    >
      <TextField
        fullWidth
        label="Wpisz umiejętność"
        id="fullWidth"
        value={input}
        placeholder="Napisz coś o sobie"
        onChange={(e) => setInput(e.target.value)}
      />

      <Select
        labelId="skill-type"
        id="skill-type"
        label="Kategoria"
        // defaultValue={type}
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <MenuItem value={"Techniczne"}>Techniczne</MenuItem>
        <MenuItem value={"Gastronomiczne"}>Gastronomiczne</MenuItem>
        <MenuItem value={"Miękkie"}>Miękkie</MenuItem>
      </Select>
      <Typography id="skill-slider" gutterBottom>
        Określ poziom:
      </Typography>
      <Slider
        labelId="skill-slider"
        placeholder="Typ"
        defaultValue={"test"}
        value={percentage}
        onChange={(e) => setPercentage(e.target.value)}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
      <Button
        variant="outlined"
        onClick={() => handleClick()
          
        }
      >
        Dodaj
      </Button>
    </Box>
  );
}

export default NewSkill;
