import React from "react";
import { ResponsivePie } from "@nivo/pie";

const ProfileSectionScoreRadialChart = ({ profileScore }) => {
  const CenteredMetric = ({ centerX, centerY, dataWithArc }) => {
    const metricValue = profileScore?.userPercentage
      ? `${Math.floor(profileScore?.userPercentage)}%`
      : "0%";
    const metricLabelBottom = "ŚREDNIEJ";

    return (
      <g transform={`translate(${centerX},${centerY})`} textAnchor="middle">
        <text fontSize={28} fill="#d2ab67" dy="0em">
          {metricValue}
        </text>
        <text fontSize={12} fill="#d2ab67" dy="2.1em">
          {metricLabelBottom}
        </text>
      </g>
    );
  };

  return (
    <ResponsivePie
      data={[
        {
          id: "Profile poniżej twojego rozwoju ścieżki (%)",
          label: `Profile poniżej: ${profileScore?.userPercentage}`,
          value: profileScore?.userPercentage,
          color: "hsl(25, 70%, 50%)",
        },
        {
          id: "Profile powyżej twojego rozwoju ścieżki (%)",
          label: `Profile powyżej: ${100 - profileScore?.userPercentage}`,
          value: 100 - profileScore?.userPercentage,
          color: "hsl(25, 70%, 50%)",
        },
      ]}
      margin={{
        top: 30,
        right: 30,
        bottom: 30,
        left: 30,
      }}
      innerRadius={0.8}
      padAngle={0.7}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      colors={["#FFA726", "#d9d9d9"]}
      enableArcLinkLabels={false}
      layers={["legends", "arcs", "arcLinkLabels", CenteredMetric]}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: -40,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 25,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default ProfileSectionScoreRadialChart;
