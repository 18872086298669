import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ProfileScoreRadialChart from "../Charts/ProfileScoreRadialChart";
import { SectionPaper } from "../SectionPaper";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useDispatch, useSelector } from "react-redux";
import { getPrkStats } from "../../Slices/competencePaths";
import { useNavigate } from "react-router-dom";

const PrkLevelBox = ({ currentProfile }) => {
  const dispatch = useDispatch();
  const profileScore = useSelector((state) => state.competencePahts.prkStats);
  const token = useSelector((state) => state.auth.user.jwt);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPrkStats(token));
  }, [dispatch]);

  const handleClick = () => {
    navigate(`/profil-kompetencyjny/${currentProfile.id}`);
  };

  return (
    <SectionPaper elevation={3} sx={{ alignItems: "center" }}>
      {profileScore ? (
        <Grid container spacing={0}>
          {currentProfile ? (
            <Grid item xs={8}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography
                    component="h3"
                    variant="subtitle2"
                    align="center"
                    color="text.primary"
                    margin={1}
                  >
                    Twój <br />
                    <span style={{ color: "#55E098", fontSize: "12px" }}>
                      {currentProfile?.attributes.profileName.toUpperCase()}
                    </span>
                    <br />
                    ma zebrane więcej osiągnięć od:
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    endIcon={<ArrowRightAltIcon />}
                    onClick={() => handleClick()}
                    sx={{
                      margin: 1,
                      fontSize: "10px",
                      background: "#FFA726",
                    }}
                  >
                    PORÓWNAJ PROFIL Z INNYMI
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={8}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography
                    component="h3"
                    variant="subtitle2"
                    align="center"
                    color="text.primary"
                    margin={1}
                  >
                    Twoja ścieżka jest na poziomie:
                  </Typography>
                </Grid>
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}/PRK.png`}
                  alt="badge"
                  style={{ width: "100px", marginLeft: 20 }}
                />
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    endIcon={<ArrowRightAltIcon />}
                    sx={{
                      margin: 1,
                      fontSize: "10px",
                      background: "#FFA726",
                    }}
                  >
                    Informacje o poziomach prk
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={4} height={120}>
            <ProfileScoreRadialChart profileScore={profileScore} />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h7">Błąd ładowania danych</Typography>
      )}
    </SectionPaper>
  );
};

export default PrkLevelBox;
