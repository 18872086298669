import { Box, Container, Grid, List, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { SectionPaper } from "../../Components/SectionPaper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ProfileSectionsStatsBar } from "../../Components/competenceProfilesComponents/ProfileSectionsBar";
import { getCompetenceProfile } from "../../Slices/competenceProfiles";
import ProfileSectionElement from "../../Components/ProfileSectionElement";
require("moment/locale/pl");

const CompetenceProfile = () => {
  let { profileId } = useParams();
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.auth.user.jwt);
  const profile = useSelector((state) => state.competenceProfile);
  let profileSections;

  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    dispatch(getCompetenceProfile({ id: profileId, token: jwt }));
  }, [dispatch]);

  if (profile) {
    profileSections = Object.fromEntries(
      Object.entries(profile)?.filter(
        ([key, value]) =>
          typeof value === "object" && value !== null && key !== "profileStats"
      )
    );
  }

  return (
    <main>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
        >
          {profile?.profileName}
        </Typography>
      </Box>
      <Container spacing={0}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <SectionPaper elevation={3}>
              <ProfileSectionsStatsBar />
            </SectionPaper>
          </Grid>
          <Grid item xs={6}>
            <Grid container gap={4}>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {profileSections &&
                  Object.keys(profileSections).map((section, i) => (
                    <ProfileSectionElement
                      key={i}
                      profile={profile}
                      name={section}
                      section={profileSections[section]}
                      expanded={expanded}
                      setExpanded={setExpanded}
                    />
                  ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default CompetenceProfile;
